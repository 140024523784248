@import 'globals';

.iframe {
  box-shadow: 0 0 37px -7px rgba(0, 0, 0, 0.5);
  margin: 30px;

  iframe {
    border-width: 0;
  }
}


