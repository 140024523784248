#header-main #main-nav .dropdown-counter {
    right: 60px;
}

.three-col .usergroup-tr td {
    min-height: 65px;
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
}
.three-col .usergroup-tr td:nth-child(1) {
    width: 15%;
}

.three-col .usergroup-tr td:nth-child(2) {
    width: 5%;
}

.three-col .usergroup-tr td:nth-child(3) {
    width: 80%;
}


.with-divider {
    position: relative;
}
.with-divider:after, .absence-view.with-divider:before {
    content: '';
    position: absolute;
    display: block;
    top: 2px;
    width: 100%;
    height: 1px;
    background-color: white;
    left: 0;
}

.absence-view.with-divider:after {
    background-color: #c1c1c1;
}
.absence-view.with-divider:before {
    background-color: #c1c1c1;
    top: 0px;
}


.badge {
    padding: 2px;
    margin: 2px;
    min-width: 20px;
    display: inline-block;
    text-align: center;
    background-color:#fefefe;
    border-radius: 2px;
    color: white;
}

.badge-blue {
    background-color:#0076aa;
}

.badge-green {
    background-color:#00aa00;
}

.badge-red {
    background-color:#e10314;
}

.badge-yellow {
    background-color:#ffdf00;
}

.twitter-picker {
  div:last-of-type {
    >div:nth-of-type(1){
      display: none !important;
    }
    >div:nth-of-type(2){
      display: none;
    }
  }

}
