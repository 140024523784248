/* Corporate */
$primary-color: rgba(226, 4, 20, 1);
$primary-color-lighter: rgba(226, 4, 20, 0.9);
$secondary-color: rgba(57, 64, 77, 1);
$secondary-color-light: rgba(57, 64, 77, 1);

/* Basic */
$white: rgba(254, 254, 254, 1);
$white-trans-10: rgba(254, 254, 254, 0.1);
$white-trans-30: rgba(254, 254, 254, 0.3);
$white-trans-50: rgba(254, 254, 254, 0.5);
$white-trans-80: rgba(254, 254, 254, 0.8);
$white-trans-90: rgba(254, 254, 254, 0.9);
$ultra-light-gray: rgba(247, 247, 247, 1);
$light-gray: rgba(237, 237, 237, 1);
$medium-gray: rgba(115, 115, 115, 1);
$medium-gray-trans-30: rgba(115, 115, 115, 0.3);
$medium-gray-trans-70: rgba(115, 115, 115, 0.7);
$medium-gray-trans-90: rgba(115, 115, 115, 0.9);
$dark-gray: rgba(48, 48, 48, 1);
$dark-gray-trans-20: rgba(48, 48, 48, 0.2);
$dark-gray-trans-50: rgba(48, 48, 48, 0.5);
$dark-gray-trans-70: rgba(48, 48, 48, 0.7);
$black: rgba(0, 0, 0, 1);

$yellow: rgba(255, 223, 0, 1);
$green: rgba(0, 170, 0, 1);
$blue: rgba(0, 118, 170, 1);
$orange: rgba(255, 163, 0, 1);

$light-green: rgba(121, 199, 121, 1);
$light-blue: rgba(128, 191, 186, 1);
$light-yellow: rgba(237, 223, 122, 1);
$light-orange: rgba(237, 184, 122, 1);

// Table color
$border-table-color: rgba(193, 193, 193, 1);

/* --- Font ---------- */
$primary-font-family: 'Roboto', sans-serif;

/* --- Breakpoints ---------- */
$breakpoint-large: 1440px;
$breakpoint-medium: 1023px;
$breakpoint-medium-small: 900px;
$breakpoint-small: 639px;

$global-width: 1330px;
$global-gutter: 70px;

/* --- Miscellaneous ---------- */
$global-transition: all 0.35s;
$header-height: 90px;
$header-height-mobile: 60px;

/* --- Off-Canvas ---------- */
$off-canvas-width: 300px;
