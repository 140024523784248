@import 'styles/globals';

.FileInput {
  position: relative;
  display: block;
  box-sizing: border-box;
  height: auto;
  min-height: 82px;
  padding: 1px;
  cursor: pointer;
  border: 1px solid $light-gray;
  outline: none;
  background-color: white;

  label {
    position: absolute;
    top: 15px;
    left: 30px;
  }

  &--active {
    padding: 0;
    border: 2px dashed #2196f3;
  }

  &--reject {
    border-color: $primary-color;
  }

  &--single {
    &__thumbnail {
      max-width: 60%;
    }
  }

  &__thumbnail-list {
    display: flex;
    align-items: flex-start;
    flex: 1;
    flex-flow: row wrap;
    margin: 30px 28px 0;
  }

  &__thumbnail {
    position: relative;
    box-sizing: border-box;
    min-width: 80px;
    max-width: 30%;
    height: 45px;
    margin-right: 10px;
    border: 1px solid $dark-gray-trans-20;
    text-align: center;

    &__meta {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      max-height: 100%;
      transition: $global-transition;
      opacity: 0;
      background-color: $light-gray;
      text-align: center;
      font-size: 12px;
      line-height: 1.15;
    }

    &:hover &__meta {
      opacity: 0.8;
    }

    &__remove {
      position: absolute;
      top: -3px;
      right: -2px;
      background: rgba(255, 255, 255, 0.5);
      border-bottom-left-radius: 4px;
      height: auto;

      &:hover {
        height: 24px;
      }

      .icon {
        font-size: 20px;
      }
    }

    a {
      display: block;
      width: 100%;
      height: 100%;
      color: $dark-gray;

      .icon {
        margin-top: 9px;

        :hover {
          color: $dark-gray;
        }
      }
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &--readonly,
  & &--readonly .icon,
  &--readonly :hover .icon {
    color: $dark-gray !important;
    background-color: $light-gray;
  }

  & &__upload-icon {
    position: absolute;
    top: 0;
    right: 0;
    box-sizing: border-box;
    height: 100%;
    padding: calc(82px / 2 - 15px) 24px 0;
    text-align: right;

    &:hover {
      .icon {
        color: $primary-color;
      }
    }
  }
}
