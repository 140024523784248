// Select-Menu

.dropdownFilter {
  min-width: 250px;
  width: auto;
  height: 48px;
  display: flex;
  position: relative;
  align-items: center;

  @media screen and (max-width: $breakpoint-medium-small) {
    width: 100%;
    box-sizing: border-box;
  }

  ul {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 42px;
    max-height: 42px;
    border: 2px solid $dark-gray;

    li {
      list-style: none;
      position: relative;
      margin: 0;
      border-bottom: 1px solid $dark-gray-trans-20;
      min-width: 182px;
      width: 100%;

      a {
        width: 250px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        position: relative;
        line-height: normal;
        height: 42px;
        z-index: 300;
        font: {
          size: 16px;
          weight: 600;
        }
        padding: 0 30px 0 10px;

        @media screen and (max-width: $breakpoint-small) {
          font-size: 12px;
        }

        @media screen and (max-width: $breakpoint-large) {
          width: 100%;
        }

        text-transform: uppercase;
        color: $dark-gray;
        text-decoration: none;
        transition: $global-transition;
        background-color: $white;

        &::after {
          content: '';
          position: absolute;
          width: 12px;
          height: 12px;
          border-top: 3px solid $dark-gray;
          border-right: 3px solid $dark-gray;
          transform: rotate(135deg);
          top: 10px;
          right: 15px;
          border-radius: 3px;
          @media screen and (max-width: $breakpoint-large){
            right: 9px;
          }

          &:hover {
            border-color: white;
          }
        }

        &:hover {
          cursor: pointer;
          background-color: $dark-gray;
          color: white;

          &::after {
            border-color: white;
          }

          &:disabled {
            cursor: default;
          }
        }
      }

      ul {
        display: block;
        visibility: hidden;
        opacity: 0;
        overflow: auto;
        position: absolute;
        z-index: 999;
        right: -2px;
        top: 46px;
        height: auto;
        max-height: 250px;
        width: 182px;
        max-width: max-content;
        min-width: 100%;
        background: $white;
        transition: $global-transition;
        transition-delay: 0.15s;
        border-top: 0 none;

        li {
          margin: 0;
          height: 36px;

          &::after {
            display: none;
          }

          a {
            line-height: 16px;
            padding: 0 11px;
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            color: $dark-gray;
            font: {
              size: 16px;
            }
            &:hover {
              background: $light-gray;
            }

            &::before {
              display: none;
            }

            &::after {
              display: none;
            }
          }
        }
      }

      &.active {
        transition-delay: 0s;

        a {
          background-color: white;
          color: $dark-gray;

          &::after {
            transform: rotate(-45deg);
            border-color: $dark-gray;
            top: 17px;
          }
        }

        ul {
          visibility: visible;
          opacity: 1;

          li {
            margin: 0;
            a {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
