@import 'styles/globals';

.LoadingAnimation {
  display: inline-block;
  width: 32px;
  height: 32px;
  opacity: 1;
  border: 6px solid #f3f3f3;
  border-top-color: $primary-color;
  border-radius: 50%;
  transition: opacity 0.24s ease;
  animation: LoadingAnimation__spin 2s ease-in-out infinite;

  &--hidden {
    opacity: 0;
  }
}

@keyframes LoadingAnimation__spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
