/* the container must be positioned relative: */
.custom-select {
  position: relative;

  &--readonly {
    .select-selected {
      background-color: $light-gray;
      color: $dark-gray-trans-70;
    }
  }

  label {
    z-index: 5000;
    position: absolute;
    top: 15px;
    font-size: 16px;
  }

  select {
    display: none; /* hide original SELECT element: */
  }
}

.select-selected {
  background-color: $white;
  border-radius: 3px;
  color: $dark-gray;
  font-size: 22px;
  font-weight: bold;
  padding: 40px 30px 15px 30px;
  border: 1px solid $light-gray;
  cursor: pointer;
  user-select: none;

  /* style the arrow inside the select element: */
  &::after {
    position: absolute;
    content: '\f107';
    font-family: FontAwesome; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
    top: 30px;
    right: 35px;
    width: 0;
    height: 0;
  }

  &.select-arrow-active {
    box-shadow: 0 0 30px 0 rgba(29, 29, 27, 0.15);
    border-radius: 3px 3px 0 0;
  }

  /* point the arrow upwards when the select box is open (active): */
  &.select-arrow-active::after {
    content: '\f106';
    font-family: FontAwesome; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
  }

  & > span {
    position: absolute;
    top: 15px;
    font-size: 16px;
    font-weight: 700;
    color: #c1c1c1;
  }
}

/* style items (options): */
.select-items {
  position: absolute;
  background-color: $white;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 6000;
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
  text-overflow: ellipsis;

  &:not(.select-hide) {
    box-shadow: 0 10px 30px 0 rgba(29, 29, 27, 0.15);
  }

  /* hide the items when the select box is closed: */
  &.select-hide {
    display: none;
  }

  /* style the items (options), including the selected item: */
  .select-item {
    position: relative;
    color: $dark-gray;
    font-size: 22px;
    font-weight: bold;
    padding: 15px 30px;
    border-top: 1px solid $light-gray;
    border-left: 1px solid $light-gray;
    cursor: pointer;
    user-select: none;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      background-color: $light-gray;
    }

    &--selected {
      background-color: $light-gray;

      &::after {
        position: absolute;
        top: calc(50% - (22px / 2));
        right: 10px;
        content: '\f14a';
        font-family: FontAwesome; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
      }
    }
  }
}
