.counter {
  align-items: center;
  justify-content: center;
  display: flex;
  color: $white;
  font-size: 18px;
  line-height: 21px;
  width: 40px;
  height: 26px;
  background-color: $dark-gray;
  border-radius: 20px;

  &-small {
    width: 29px;
    font-size: 14px;
    line-height: 16px;
    height: 18px;
    border-radius: 50px;
  }

  &-gray {
    background: $medium-gray;
  }
}
