@import 'globals';

.custom-select.disabled > .select-selcted{
    background-color: #ededed;
}

input.editStockInput {
    padding: 5px 5px;
    width: 40px;
    font-size: 14px;
}

input.hiddenStockInput {
    display:none;
}

.sticky-header {
    background: red;
    color: green;
    margin-top: -65px;
    position: absolute;
}

  .color-line {
    border: none;
    float: left;
    width: calc(20% - 20px);
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    text-align: center;
    height: 75px;

    &+.color-line {
      margin-left: 15px;
    }

    @media screen and (max-width: $breakpoint-medium) {
      width: calc(50% - 20px);
      flex-wrap: wrap;
      margin-bottom: 15px;

      &+.color-line {
        margin-left: 0;


        &:nth-child(even){
          margin-left: 15px;
          float: right;
        }
        &:last-child{
          margin-bottom: 0;
        }
      }
    }

    @media screen and (max-width: $breakpoint-small) {
      width: 100%;
      flex-wrap: wrap;
      margin-bottom: 5px;

      &+.color-line {
        &:nth-child(even){
          float: right;
        }
        &:last-child{
          margin-bottom: 0;
        }
      }
    }

    .color-cell {
      box-sizing: border-box;
      text-align: center;
      height: unset;
      width: unset;
    }
  }




// Bestand border
.table-col.table-col-wide {
  border-right: 1px solid rgb(125,125,125);
}

//farblegende
.colorKey {
  display: flex;
  margin: 0;
  padding: 0 30px 10px;
  justify-content: flex-end;
  align-items: center;
  background: white;
  flex-wrap: wrap;

  li {
    list-style: none;
    display: flex;
    width: max-content;
    justify-content: center;
    margin-left: 10px;
    align-items: center;
    height: unset;

    .color-block {
      width: 16px;
      height: 16px;
    }

    .color-name {
      font-size: 14px !important;
      display: flex;
      width: max-content;
      align-items: flex-end;
    }
  }
}
