.accordion-body-wrapper {
  padding: 15px;
  overflow: visible;

}

.site-wrapper > div.accordion-body-wrapper {
  background-color: #ededed;
}

.dropdown-wrapper {
  padding-top: 15px;
}
.copy-from {
  margin-bottom: 15px;
}

.text-input-wrapper {
  margin: 15px;
}
