.breadcrumb-menu {
  padding: 10px 30px;
  box-sizing: border-box;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: auto;
  min-height: 86px;
  flex-wrap: wrap;

  @media screen and (max-width: $breakpoint-small) {
    margin: 0;
  }

  > ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: flex-start;
    max-height: 24px;

    li {
      padding: 0;
      list-style: none;
      margin: 0 40px 0 0;
      position: relative;
      height: 24px;

      &:last-of-type {
        margin-right: 0;
      }

      a {
        text-decoration: none;
        color: $dark-gray;
        font-size: 20px;
        font-weight: 600;
        line-height: 29px;
        height: 100%;
        display: flex;
        align-items: center;

        i {
          font-size: 26px;
          line-height: 21px;
        }
      }

      &::after {
        content: '';
        position: absolute;
        width: 6px;
        height: 6px;
        border-top: 4px solid $medium-gray;
        border-right: 4px solid $medium-gray;
        transform: rotate(45deg);
        right: -23px;
        top: 7px;
        bottom: 0;
        border-radius: 2px;
      }

      &:last-of-type {
        &::after {
          display: none;
        }
      }
    }
  }

  .arrow-right {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 6px;
      height: 6px;
      border-top: 4px solid $medium-gray;
      border-right: 4px solid $medium-gray;
      transform: rotate(45deg);
      right: -23px;
      top: 7px;
      bottom: 0;
      border-radius: 2px;
    }
  }

  > div {
    display: flex;
    align-items: center;
    > div + div {
      margin-left: 15px;
      transition: $global-transition;
      &:first-of-type {
        margin-left: 0;
      }
      @media screen and (max-width: $global-width) {
        transition: $global-transition;
      }
    }
  }

  .dropdownFilter {
    @media screen and (max-width: 800px) {
      padding: 0;
      width: 200px;
      min-width: 200px;
    }

    ul {
      li {
        a {
          padding: 0 30px 0 10px;
          display: flex;
          align-items: center;
          line-height: normal;
        }
      }
    }
  }

  .btn-3 {
    a {
      span {
        @media screen and (max-width: 1024px) {
          padding: 10px;
        }
      }
    }
  }
}
