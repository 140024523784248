@import 'styles/globals';

.card {
  overflow: hidden;
  background-color: white;
  box-shadow: 0 0 37px -7px rgba(0, 0, 0, 0.5);

  &__header {
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    position: relative;
    border-bottom: 1px solid $light-gray;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 77px;
      height: 6px;
      width: 60px;
      background-color: $primary-color;
    }

    &_title {
      font-size: 28px;
      font-weight: bold;
      line-height: 33px;
    }

    &_extra {
      margin-left: auto;
      color: $dark-gray;

      input {
        padding: 3px 5px;
        border: 2px solid rgb(48, 48, 48);
        line-height: 22px;
        font-size: 16px;
      }
    }

    &_icon {
      color: $medium-gray-trans-70;
      font-size: 30px;
      margin-right: 20px;
    }
  }

  &__footer {
    height: 106px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid $ultra-light-gray;
  }
}
