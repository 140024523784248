// Import Fonts here
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');

/* prettier-ignore */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src:
    local('Roboto Light'),
    local('Roboto-Light'),
    url('../fonts/roboto-v20-latin-300.woff2') format('woff2'),
    url('../fonts/roboto-v20-latin-300.woff') format('woff');
}

/* prettier-ignore */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src:
    local('Roboto'),
    local('Roboto-Regular'),
    url('../fonts/roboto-v20-latin-regular.woff2') format('woff2'),
    url('../fonts/roboto-v20-latin-regular.woff') format('woff');
}

/* prettier-ignore */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src:
    local('Roboto Medium'),
    local('Roboto-Medium'),
    url('../fonts/roboto-v20-latin-500.woff2') format('woff2'),
    url('../fonts/roboto-v20-latin-500.woff') format('woff');
}

/* prettier-ignore */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src:
    local('Roboto Bold'),
    local('Roboto-Bold'),
    url('../fonts/roboto-v20-latin-700.woff2') format('woff2'),
    url('../fonts/roboto-v20-latin-700.woff') format('woff');
}
