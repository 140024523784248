.group {
  position: relative;

  > input[type=text] {
    font-size: 16px;
  }

  textarea + label {
    background: white;
    left: 2px;
    top: 1px;
    width: calc(100% - 20px);
    padding: 14px 30px 5px;
    box-sizing: border-box;
  }
}

input {
  font-size: 22px;
  font-weight: 700;
  padding: 40px 30px 15px 30px;
  width: calc(100% - 60px);
  display: block;
  border: 1px solid $light-gray;
  border-radius: 3px;
  -webkit-appearance: none;

  &.test {
    font-size: 400px;
    font-weight: bolder;
  }
}
.rte-font input {
  appearance: auto;
  display: initial;
  width: 13px;
}
.rte-font label {
  position:static;
  font-size:14px;
  color: #000;
  font-weight: 500;
}


div[class^='InputPopover__checkOption']:nth-child(2) span, {
  display: none;
}
div[class^='InputPopover__checkOption']:nth-child(2):after, {
  content: 'Link im neuen Tab öffnen'
}
div[class^='InputPopover__checkOption']:nth-child(3) {
  display: none;
}

input[type='file'] {
  display: none;
}

input:focus {
  outline: none;
}

input:disabled,
input:read-only {
  color: $dark-gray-trans-70;
  background-color: $light-gray;
}

.grayed {
  input {
    color: #c1c1c1;
  }
}

textarea {
  font-size: 16px;
  font-weight: bold;
  padding: 40px 30px 15px 30px;
  width: calc(100% - 60px);
  display: block;
  border: 1px solid $light-gray;
  height: 240px;
  resize: none;
  border-radius: 3px;
}

textarea:active,
textarea:focus {
  outline: 0 !important;
}

label {
  color: rgba(193, 193, 193, 1);
  font-size: 16px;
  position: absolute;
  font-weight: bold;
  left: 30px;
  top: 15px;
  transition: 0.2s ease all;
}
