@import 'settings';
@import 'absenceButton';
@import 'Atoms/BreadCrumbsMenu';
@import 'Atoms/Btn';
@import 'Atoms/ContentWrapper';
@import 'Atoms/formbutton';
@import 'Atoms/formdropdown';
@import 'Atoms/formfield';
@import 'Atoms/formimage';
@import 'Atoms/Counter';
@import 'Atoms/_dropdownFilter.scss';
@import 'Molecules/CheckList';
@import 'Molecules/Dokumente';
@import 'Molecules/Login';
@import 'Molecules/News';
@import 'Molecules/NewsList';
@import 'Molecules/NewsText';
@import 'Molecules/Vacation';
@import 'Organisms/header';
@import 'ExternalComponents/Accordion';
@import 'tableSortiment';

/* --- Layout-Styles ---------- */

// Create layout-style here
html {
  background: white;
}

body {
  position: relative;
  overflow-x: hidden;
  font-weight: 500;
  background: #ededed;
  font-family: $primary-font-family;
  box-sizing: border-box;
  z-index: 9999;
  margin: 0;

  &::after {
    content: '';
    width: 100%;
    height: 2px;
    background-color: $primary-color;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    position: fixed;
  }
}

.page-wrapper {
  display: flex;
}

#main-side {
  width: 100%;
  box-sizing: border-box;
  background-color: $light-gray;

}

main {
  @media screen and (max-width: $breakpoint-small) {
    padding: 0;
  }
}

// Marker

.yellow-mark {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    height: 100%;
    width: 6px;
    background-color: $yellow;
  }
}

.green-mark {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    height: 100%;
    width: 6px;
    background-color: $green;
  }
}

// Table Border Color

.table-green {
  border-bottom: 4px solid $light-green;
}

.table-yellow {
  border-bottom: 4px solid $light-yellow;
}

.table-blue {
  border-bottom: 4px solid $light-blue;
}

.table-orange {
  border-bottom: 4px solid $light-orange;
}

// Table Text color

.green-text {
  span {
    color: $green;
  }
}

.red-text {
  span {
    color: $primary-color;
  }
}

// Headline inkl counter
.headline {
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  background: white;
  width: 100%;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 77px;
    height: 6px;
    width: 60px;
    background-color: $primary-color;
  }

  span {
    font-size: 28px;
    font-weight: bold;
    line-height: 33px;
    display: flex;
    align-items: center;
  }

  i {
    color: $medium-gray-trans-70;
    font-size: 30px;
    margin-right: 24px;
  }

  a {
    &:last-of-type {
      i {
        margin-right: 0;
      }
    }

    i {
      color: $dark-gray;
      transition: $global-transition;

      &:hover {
        transition: $global-transition;
        color: $primary-color;
      }
    }
  }
}

.headline-counter {
  padding: 30px;
  box-sizing: border-box;
  justify-content: space-between;
  background: white;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 77px;
    height: 6px;
    width: 60px;
    background-color: $primary-color;
  }

  .counter {
    line-height: 21px;
    font-size: 18px;
  }

  span {
    font-size: 28px;
    font-weight: bold;
    line-height: 33px;
    display: flex;
    align-items: center;
  }

  i {
    color: $medium-gray-trans-70;
    font-size: 30px;
    margin-right: 24px;
  }

  a {
    &:last-of-type {
      i {
        margin-right: 0;
      }
    }

    i {
      color: $dark-gray;
      transition: $global-transition;

      &:hover {
        transition: $global-transition;
        color: $primary-color;
      }
    }
  }

  .btn {
    span {
      padding: 0 20px;
    }
  }

  > div {
    > div + div, div + input, input + input {
      margin-left: 15px;
    }
  }
}

.site-wrapper {
  box-shadow: 0 0 37px -7px rgba(0, 0, 0, 0.5);
  display: grid;

  > div {
    &:nth-child(even) {
      background-color: $light-gray;
    }

    &:nth-child(odd) {
      background-color: $white;
    }
  }

  > a {
    &:nth-child(even) {
      background-color: $light-gray !important;
    }
  }
}

.table {
  width: 100%;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 10px;
    top: 0;
    left: 981px;
    z-index: 1;
    bottom: 0;
    box-shadow: inset 9px 0 16px -8px rgba(0, 0, 0, 0.3);

    @media screen and (max-width: 1600px) {
      left: 423px;
    }
  }

  &::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 10px;
    top: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset -9px 0 16px -8px rgba(0, 0, 0, 0.3);
  }

  .table-head {
    &:first-of-type {
      //width: 200px;
    }
  }
}

.table,
.table-re {
  background-color: white;
  display: flex;
  position: relative;

  .table-head {
    background: $dark-gray;
    width: auto;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 65px;
    padding: 0 20px;
    min-width: 68px;
    border-top: 1px solid #c1c1c1;

    span {
      color: white;
      width: max-content;
      font: {
        size: 22px;
        weight: 400;
      }

      line-height: 25px;
      pointer-events: all !important;
    }
  }

  .table-col {
    width: min-content;
    position: relative;
  }

  .table-content-1 {
    > div {
      background-color: $white;

      &:nth-child(even) {
        i {
          background-color: $light-gray;
        }
      }
    }

    display: flex;
    flex-direction: column;

    a {
      color: $dark-gray;
      text-decoration: none;
      height: 65px;
      width: 100%;
      display: block;

      &:hover {
        color: $primary-color;
      }

      .fa .fa-pencil {
        color: green;
      }
    }

    i {
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
      font-size: 26px;
      width: 100%;
      height: 100%;
    }

    span {
      background-color: #ffffff;
      font-size: 16px;
      line-height: 19px;
      min-height: 19px;
      display: flex;
      align-items: center;
      height: 65px;
      pointer-events: all;
      padding: 0 20px;
      max-width: 100%;
      width: auto;

      &:nth-child(even) {
        background-color: $light-gray;
      }
    }
  }

  .table-content-2 {
    display: flex;
    width: auto;

    .table-left,
    .table-right {
      display: flex;
      flex-direction: column;

      span {
        background-color: $white;
        width: 100%;
        height: 65px;
        padding: 0 20px;
        box-sizing: border-box;
        font-size: 16px;
        line-height: 19px;
        align-items: center;
        display: flex;

        &:nth-child(even) {
          background-color: $light-gray;
        }
      }
    }

    .table-left {
      border-right: 1px solid $border-table-color;
      min-width: 110px;
    }

    .table-right {
      width: max-content;
    }
  }

  .scroll-x-able {
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    position: relative;
  }
}

.safari_table {
  .scroll-x-able {
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    position: relative;
  }
}

.table-head-active {
  background: $primary-color;
}

table {
  tr {
    &:nth-child(odd) {
      background-color: $light-gray !important;
    }
  }
}

.table-re {
  .table-col {
    width: calc((100% / 12) * 2 - 1px);
  }

  .table-col-5 {
    width: calc((100% / 12) * 2.4);
  }

  input {
    display: none;
  }

  label span {
    height: 21px !important;
    width: 21px !important;
    min-height: 21px !important;
    max-height: 21px !important;
    min-width: 21px !important;
    max-width: 21px !important;
    border: 2px solid $dark-gray;
    border-radius: 4px;
    display: inline-block;
    position: relative;
    margin: 0;
    padding: 0;
  }

  [type='checkbox']:checked + span::before {
    content: '\2714';
    position: absolute;
    width: 100%;
    color: #ffffff;
    background-color: #303030;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  label {
    width: 100%;
    justify-content: center;
    background-color: white;
    display: flex;
    align-items: center;
    height: 65px;
    position: relative !important;
    top: 0;
    left: 0;

    &:nth-child(even) {
      background: $light-gray;
    }

    span {
      padding: 0 !important;
      background-color: transparent !important;

      &:nth-child(odd) {
        background-color: $light-gray !important;
      }
    }
  }
}

.centered {
  span {
    display: flex;
    justify-content: center;
  }
}

// Settings
table {
  border-collapse: collapse;
  width: 100%;

  tr {
    &.table-overlay {
      td {
        span {
          color: $dark-gray-trans-20;

          i {
            color: $dark-gray;
          }
        }
      }
    }

    &:nth-child(even) {
      background-color: white;
    }

    th {
      background: #303030;
      width: auto;
      box-sizing: border-box;
      height: 65px;
      padding: 0 20px;
      text-align: left;
      border-right: 1px solid $border-table-color;

      span {
        color: #ffffff;
        width: max-content;
        font-size: 22px;
        font-weight: 400;
        line-height: 25px;
      }
    }

    td {
      height: 65px;
      box-sizing: border-box;
      padding: 0 20px;
      border-right: 1px solid $border-table-color;

      &:last-of-type {
        display: flex;
        justify-content: left;
        align-items: center;
        border: 0 none;
        width: auto;

        span {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          i {
            font-size: 26px;
            margin: 0;

            &:hover {
              color: $primary-color;
            }
          }
        }
      }
    }
  }
}

.table-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .site-list {
    box-shadow: 0 0 37px -7px rgba(0, 0, 0, 0.5);

    ul {
      margin: 0;
      padding: 0;
      display: flex;
      background-color: white;
      border-radius: 3px;

      li {
        list-style: none;
        width: 65px;
        height: 50px;
        border-right: $border-table-color;

        &:last-of-type {
          border: 0 none;
        }

        a {
          text-decoration: none;
          width: 65px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $dark-gray;
        }

        &.active {
          a {
            background-color: $dark-gray;
            color: $white;
          }
        }
      }
    }
  }

  .site-next-prev {
    box-shadow: 0 0 37px -7px rgba(0, 0, 0, 0.5);
    width: 130px;
    height: 50px;
    border-radius: 3px;
    background-color: $white;
    display: flex;

    .site-next {
      width: 65px;
      height: 50px;
      display: block;
      position: relative;
      border-right: 1px solid $border-table-color;
      transition: $global-transition;

      &:hover {
        background-color: $ultra-light-gray;
        transition: $global-transition;
      }

      &::after {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        top: 16px;
        left: 26px;
        border-top: 5px solid $dark-gray;
        border-right: 5px solid $dark-gray;
        transform: rotate(225deg);
        border-radius: 3px;
      }
    }

    .site-prev {
      width: 65px;
      height: 50px;
      display: block;
      position: relative;
      transition: $global-transition;

      &:hover {
        background-color: $ultra-light-gray;
        transition: $global-transition;
      }

      &::after {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        top: 16px;
        left: 19px;
        border-top: 5px solid $dark-gray;
        border-right: 5px solid $dark-gray;
        transform: rotate(45deg);
        border-radius: 3px;
      }
    }
  }
}

.margin-top {
  // test
  margin-top: 70px;
}

th {
  font-size: 22px;
  font-weight: 500;
  line-height: 25px;
  color: white;
}

td {
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;

  i {
    font-size: 26px !important;
    margin-right: 20px;

    &:hover {
      color: $primary-color;
    }
  }
}

.three-col {
  td {
    &:nth-child(1) {
      width: 35%;
    }

    &:nth-child(2) {
      width: 50%;
    }

    &:nth-child(3) {
      width: 15%;
    }
  }
}

.overflow-x {
  overflow-x: scroll;
}

/* The Modal (background) */
.modal {
  //display: none; /* Hidden by default */
  display: block;
  position: fixed; /* Stay in place */
  z-index: 9999999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(29, 29, 27, 0.9); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: $ultra-light-gray;
  margin: 5% auto; /* 5% from the top and centered */
  border-radius: 3px;
  width: 80%; /* Could be more or less, depending on screen size */
  padding-bottom: 40px;

  .content-wrapper-box {
    padding: 0 30px;
  }

  .content-wrapper {
    background: transparent;
  }

  .site-wrapper {
    box-shadow: none;
  }

  .headline-counter {
    background: transparent;

    .close {
      i {
        color: $dark-gray;
      }
    }
  }
}

/* The Close Button */
.close {
  color: $dark-gray;
  font-size: 28px;
  font-weight: normal;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.grid-wrapper > div > .grid-wrapper {
  padding: 0;
}

.grid-wrapper > div > .grid-wrapper > div {
  padding: 0;
}

ul.tabs {
  padding: 0 40px;
  list-style: none;
  margin: 0 0 20px;
  display: flex;
  hyphens: auto;
}

ul.tabs li {
  background: rgba(193, 193, 193, 1);
  color: $white;
  padding: 20px 0;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  margin-right: 2px;
  justify-content: center;
  display: flex;
  align-items: center;
  width: 100%;

  &:first-of-type {
    border-radius: 3px 0 0 3px;
  }

  &:last-of-type {
    border-radius: 0 3px 3px 0;
    margin-right: 0;
  }

  &:hover {
    background: $dark-gray;
  }
}

ul.tabs li.current {
  background: $dark-gray;
  color: $white;
}

.tab-link {
  min-width: 124px;
}

.tab-content {
  display: none;
  background: none;
  padding: 0;
}

.tab-content.current {
  display: inherit;
}

.green-bg {
  background-color: $green !important;
  color: $white !important;
  padding: 0 5px !important;
  border-radius: 3px !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  max-height: 32px;
}

.yellow-bg {
  background-color: $yellow !important;
  color: $black !important;
  padding: 0 5px !important;
  border-radius: 3px !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  max-height: 32px;
}

.orange-bg {
  background-color: $orange !important;
  color: $black !important;
  padding: 0 5px !important;
  border-radius: 3px !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  max-height: 32px;
}

.blue-bg {
  background-color: $blue !important;
  color: $white !important;
  padding: 0 5px !important;
  border-radius: 3px !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  max-height: 32px;
}

.table-head.small {
  height: 65px;
  width: 100%;
  min-width: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  span {
    font-size: 20px;
  }

  .info {
    border: 0 none;
    font-size: 12px;
    color: rgba(223,223,223,.7);
    display: block;
    width: 100%;
    hyphens: auto;
    line-height: 1;
  }

}

.striped {
  background-image: repeating-linear-gradient(70deg, transparent, transparent 8px, rgba(223,223,223,.2) 8px, rgba(223,223,223,.2) 16px) !important;
}



.black {
  color: $white;

  > span {
    background-color: $dark-gray !important;
    box-shadow: inset 0 1px 0 0 $white;
  }
}

.content-wrapper .content-wrapper-box {
  width: 100%;
}

.header-open {
  position: absolute;
  left: 108px;
  top: 125px;
  width: 30px;
  height: 60px;
  background: $dark-gray;
  z-index: 500;

  &::after {
    content: '';
    position: absolute;
    border-left: 3px solid white;
    border-top: 3px solid white;
    width: 10px;
    height: 10px;
  }
}

.no-shadow {
  &::before,
  &::after {
    display: none;
  }
}

/// Test
.form-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0 40px;
  box-sizing: border-box;

  > div {
    //margin-right:17px;
    margin-bottom: 20px;

    &:nth-child(2n + 2) {
      margin-right: 0;
    }
  }
}

.content-form {
  .content-wrapper-box {
    .grid-wrapper {
      padding: 0 30px;

      > div {
        padding: 0 10px;
        margin-bottom: 20px;
      }
    }
  }
}

.margin-bottom-half {
  .grid-wrapper {
    > div {
      margin-bottom: 35px;
    }
  }
}

.dp-ds-none {
  display: none;

  @media screen and (max-width: 1600px) {
    display: block;
  }
}

.dp-tb-none {
  display: block;

  @media screen and (max-width: 1600px) {
    display: none;
  }
}

.centered-text {
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
}

.date {
  font-size: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 18px;
  height: 18px;
}

.dark-bg {
  width: 100%;
  background-color: $dark-gray;
  color: white;
  border-right: 1px solid $dark-gray;
}

.pers-list {
  &:nth-of-type(even) {
    background-color: $light-gray !important;
  }
}

.training {
  padding: 30px;

  .training-content {
    display: flex;

    @media screen and (max-width: 1500px) {
      flex-direction: column;
    }

    > div {
      &:first-of-type {
        width: 50%;

        @media screen and (max-width: 1500px) {
          width: 100%;
        }
      }

      &:last-of-type {
        width: 50%;
        border-left: 2px solid $dark-gray-trans-20;

        @media screen and (max-width: 1500px) {
          width: 100%;
          border-left: 0 none;
          border-top: 2px solid $dark-gray-trans-20;
        }
      }
    }
  }

  .btn-2 {
    display: block;
    width: auto;
    margin-left: 30px;

    @media screen and (max-width: 1500px) {
      margin-left: 0;

      &:last-of-type {
        margin-left: 30px;
      }
    }

    a {
      margin: 0;
      font-weight: 300;
    }
  }

  p {
    padding-right: 30px;

    @media screen and (max-width: 1500px) {
      padding-right: 0;
    }
  }

  ul {
    &:first-of-type {
      @media screen and (max-width: 1500px) {
        margin-left: 0;
        padding-left: 0;
      }

      li {
        font-weight: 600;
      }
    }

    &:last-of-type {
      padding-left: 15px;
    }

    li {
      list-style: none;
      margin-bottom: 10px;
    }
  }

  a {
    margin-left: 30px;
    margin-top: 30px;
    display: block;
    font-weight: 600;

    @media screen and (max-width: 1500px) {
      margin-left: 0;
    }
  }
}

a {
  text-decoration: underline;
  color: $primary-color;
}

.headline-small {
  font: {
    size: 26px;
    weight: 600;
  }

  line-height: 32px;
  color: $dark-gray;
}

.inline {
  display: flex;
  flex-direction: row;
  a + a {
    margin-left: 20px;
  }
  div {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.boxChecked::before {
  content: '\2714';
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #303030;
  width: 100%;
  height: 100%;
  color: #fefefe;
}

.boxUnchecked {
  height: 26px;
  width: 26px;
  min-height: 26px;
  max-height: 26px;
  min-width: 26px;
  max-width: 26px;
  border: 2px solid #303030;
  border-radius: 4px;
  display: inline-block;
  position: relative;
  pointer-events: all;
}

tr.default-table-row > td > span,
tr.default-table-row > td > span:last-of-type,
.default-list-item > span {
  width: 90%;
  height: 30%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(320deg, #ffffff, #858585);
  background-size: 400% 400%;
  animation: AnimationName 4s ease infinite;
}

.default-list-item > span {
  min-width: 315px;
  min-height: 30px;
}

@keyframes AnimationName {
  0% {
    background-position: 0 4%;
  }

  50% {
    background-position: 100% 97%;
  }

  100% {
    background-position: 0 4%;
  }
}

.modal.visible {
  display: block;
}

.modal.hidden {
  display: none;
}

.fa-check-square.pull-right {
  text-align: right;
}

.table-content-1 span > article {
  position: relative;
  width: 100%;
  height: 100%;
}

.table-content-1 span > article > b {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.3;
  font-weight: normal;
  font-size: 14px;
}

.table-content-1 span > article > span {
  background-color: inherit !important;
}

.table::before {
  display: none;
}

body {
  min-height: 100vh;
}

.headline-counter.default::before {
  display: none;
}

.absence-dropdown {
  position: absolute;
  top: 65px;
  width: 110%;
  left: -5%;
  z-index: 2;
  box-shadow: 0 0 5px #757575;
}

.absence-dropdown > li {
  list-style-type: none;
}

.absence {
  position: relative;
}

.hidden {
  display: none;
}

.popup {
  position: fixed;
  padding: 20px;
  background: #ffffff;
  border-radius: 5px;
  width: 350px;
  transition: $global-transition;
  right: 40px;
  bottom: 40px;
  height: 100px;
  border: 1px solid $dark-gray;
  z-index: 1000;

  .close {
    position: absolute;
    top: 20px;
    right: 30px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: $dark-gray;
    height: 100px;

    &:hover {
      color: $primary-color;
    }
  }

  .content {
    height: 50%;
    overflow: auto;
    padding: 0 20px;
    box-sizing: border-box;
    left: 0;
    max-width: 100%;
    top: 50px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span {
    margin-top: 0;
    font-family: Tahoma, Arial, sans-serif;
    font-size: 30px;
    line-height: 35px;
    font-weight: 600;
    color: $primary-color;
  }
}

// List Footer

.list-foo {
  height: 106px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid $ultra-light-gray;

  a {
    text-decoration: none;

    span {
      border: 2px solid $primary-color;
      border-radius: 50px;
      padding: 10px 20px;
      font-size: 20px;
      transition: $global-transition;
      color: $dark-gray;

      &:hover {
        background-color: $primary-color;
        color: $white;
        transition: $global-transition;
      }
    }
  }
}

// ModalContent

.modal-content {
  width: 100%;
  max-width: 1000px;

  .headline-counter {
    min-height: 93px;
    border-bottom: 1px solid $light-gray;
  }

  .content-wrapper-box {
    padding-top: 60px;
  }
}

.modal-content {
  .accordion-wrapper {
    .accordion {
      span {
        &:nth-child(2) {
          margin-left: 20px;
        }
      }
    }
  }
}

.dokument-item-left {
  span {
    > div {
      margin-bottom: 20px !important;

      &:last-of-type {
        margin-bottom: 0 !important;
      }
    }
  }
}

.dokument-item-right {
  span {
    > div {
      margin-bottom: 20px !important;

      &:last-of-type {
        margin-bottom: 0 !important;
      }
    }
  }
}

.sr-only {
  display: block !important;
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important; // Fix for https://github.com/twbs/bootstrap/issues/25686
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.header-input {
  font-size: 16px;
  padding: 10px 5px;
  border: 2px solid #303030;
  line-height: 22px;
  min-width: 200px
}

.header-input-group {
  position: absolute;
  left: 220px;
  top: 25px
}

.header-input-group.login {
  left: 280px;
  max-width: 200px;
}

.header-input-group.special-appointment {
  left: 350px;
  max-width: 200px;
}

.white {
  background-color: white!important;
}

.table-col {
  background: #ffffff!important;
}

@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
  .table {
    display: grid;

    .scroll-x-able {
      position: absolute;
      width: calc(100% - 151px);
      right: 0;
    }
  }
  .sortiment-table.table {
    display: flex;

    .scroll-x-able {
      position: absolute;
      width: calc(100% - 448px);
      right: 0;
    }
  }
}}

.header-wrapper::-webkit-scrollbar {
  display: none;
  opacity: 0;
  visibility: hidden;
}

.header-wrapper {
  ::-webkit-scrollbar {
    display: none;  /* Chrome Safari */
  }

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */
  overflow-y:scroll;
  overflow-x:hidden;
}

.itemPreview {
  position: relative;
  .previewItemName {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 210px;
    height: 58px;
    font-size: 13px;
    padding: 5px;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    box-sizing: border-box;
  }
  .previewItemWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 210px;
    height: 297px;
    font-size: 50px;
    border: 1px solid #c1c1c1;
    overflow: hidden;
  }
}

.RichTextEditor__root___2QXK- {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  font-family: Roboto, Arial, Georgia,serif;
  font-size: 14px;
  min-height: 250px;
}

div.rte-font {
  font-family: Roboto, sans-serif !important;
}

small.action-price-small {
  display: block;
  position: absolute;
  bottom: 10px;
  left:0;
  width: 100%;
  font-size: 11px;
  text-align: center;
}

.confirm {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.9);
    z-index: 10000;
    overflow: hidden;
}

.news-modal-after-login-wrapper {
  > div {
    &:nth-child(even) {
      background-color: $light-gray;
    }

    &:nth-child(odd) {
      background-color: $white;
    }
  }
}



.pt-10 {
  padding-top: 10px;
}
.pt-15 {
  padding-top: 15px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-40 {
  padding-top: 40px;
}
.pl-60 {
  padding-left: 60px;
}

/* The switch - the box around the slider */
.switch {
  position: absolute;
  display: inline-block;
  width: 60px;
  height: 34px;
  left: 70px;
  top: 32px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #e20414;
}

input:focus + .slider {
  box-shadow: 0 0 1px #e20414;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 9999999999 !important;
}

.article-index {
  position: relative;
  overflow: visible;
}

.color-palette {
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-top: 30px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 20px #000;
}

.close-color-palette {
  display: block !important;
  position:absolute;
  right: 15px;
  top: 5px;
  font-size: 20px;
}

.color-line {
  display: flex;
  font-size: 24px;
  border-bottom: 1px solid #ccc;
}

.color-cell {
  width: 26px;
  height: 20px;
  margin: 2px;
  flex: 1;
}
.color-palette-buttons {
  flex-direction: row;
  display: flex;
}

a.color-palette-button {
  background-color: #e20414;
  color: white !important;
  padding: 10px;
  text-align: center;
  box-sizing: border-box;
  text-decoration: none;
  border-radius: 20px;
  height: 39px !important;
  margin: 5px;
  margin-top: 15px;

}
.color-palette-button:hover {
  color: white !important;
}

.table .table-content-1 span.special-appointment-to-long-popup, .table-re .table-content-1 span.special-appointment-to-long-popup{
    position: absolute;
    left: 130px;
    top: -60px;
    z-index: 2;
    width: 300px;
    height: 150px;
    min-width: 300px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 10px rgb(60, 60, 60);
    display: none;

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 0px;
      height: 0px;
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;

      border-right:15px solid #e20414;
      top:75px;
      left:-15px;

    }
    b {
      font-weight: 400;
    }
    b.close-special-appointment-to-long-popup {
      position: absolute;
      display: block;
      right: 10px;
      top: 10px;
    }
    &.visible {
      display: block;
      padding: 15px
    }
}

.table.no-shadow .table-content-1 span.info.info-popup:hover {
  color:#e20414;
  cursor: pointer;
}

.year-filter input {
  max-width: 60px;
}

.white-status-bg-present {
  background-color: white !important;
  color: #00aa00 !important;
  padding: 0 5px !important;
  border-radius: 3px !important;
  border: 1px solid rgba(4, 169, 2, 0.7) !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  max-height: 32px;
}

.tabs-training.react-tabs__tab-list {
  padding: 0 !important;
}

.react-tabs__tab-panel > div:nth-child(even) {
  background-color: #ededed;
}
.react-tabs__tab-panel > div:nth-child(odd) {
  background-color: #fefefe;
}
input.rmdp-input {
  font-size: 22px;
  font-weight: 700;
  padding: 40px 30px 15px 30px;
  width: calc(100% - 60px);
  display: block;
  border: 1px solid #ededed;
  border-radius: 3px;
  -webkit-appearance: none;
}

div.rmdp-container {
  display: block !important;
}

.rmdp-header {
  text-align: center;
  background-color: #f0f0f0;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  padding-top: 8px;
  position: relative;
  margin: 0 !important;
}

.rmdp-week-day {
  color: #000 !important;
  cursor: default;
  font-size: 13px;
  font-weight: 500;
}

.rmdp-week:first-of-type {
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  margin: -5px;
  padding: 0 5px;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: #e20414 !important;
  box-shadow: 0 0 3px #8798ad;
  color: #fff;
}

.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
  background-color: #e20414 !important;
  color: #fff;
  opacity: 0.5;
}

.rmdp-day.rmdp-today span {
  background-color: #e20414 !important;
  color: #fff;
}

.rmdp-calendar {
  padding: 0 !important;
}

i.rmdp-arrow {
  border: solid #ccc;
  border-top-color: #ccc;
  border-top-style: solid;
  border-top-width: 0px;
  border-right-color: #ccc;
  border-right-style: solid;
  border-right-width: 2px;
  border-bottom-color: #ccc;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-left-color: #ccc;
  border-left-style: solid;
  border-left-width: 0px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  border-width: 0 2px 2px 0;
  display: inline-block;
  height: 3px;
  margin-top: 5px;
  padding: 2px;
  width: 3px;
}

.rmdp-arrow-container:hover {
  background-color: #ccc !important;
  box-shadow: 0 0 3px #8798ad;
}

.switch-overview {
  position: relative;
  left: 30px;
  top:0;
}

.pt-0 {
  padding-top: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}

.dropdown-select select{
  position: absolute;
  left: 160px;
  width: 210px;
  border-radius: 0px;
  padding: 2px;
  font-size: 14px;
  font-weight: 700;
}

.folder-red {
  color: red;
}

.folder-green {
  color: green;
}

.comment {
  border-radius: 10px;
  border: 1px solid grey;
  box-shadow: 0 0 3px #8798ad;
  padding: 20px;
  margin-bottom: 20px;
}

.comment-title {
  opacity: 1;
  margin-bottom: 10px;
}
.comment-content {
  font-style: italic;
  white-space: pre-wrap;
}

.small-textarea {
  height: 140px;
  padding-right: 60px;
  width: calc(100% - 60px);
}

.add-comment {
  position: absolute;
  bottom: 10px;
  right: -20px;
  background-color: transparent;
  border: 2px solid #e20414;
  color: #e20414;
  border-radius: 10px;
  padding: 5px 15px 5px 10px;
}
.on-dashboard {
  right: 10px;
}

.count-likes-or-comments {
  margin-left: 5px;
  margin-right: 20px;
  font-size: 70%;
}

.site-wrapper .dokument-item .dokument-item-right i.grey {
  opacity: 0.2;
}







