.accordion__item {
  color: $medium-gray;
  cursor: pointer;
  width: 100%;
  border: none;
  text-align: left;
  outline: 0;
  font-size: 18px;
  line-height: 21px;
  transition: $global-transition;
  display: flex;
  align-items: center;
  min-height: 87px;
  position: relative;
  flex-direction: column;
  box-sizing: border-box;

  &:nth-child(odd) {
    .accordion__title {
      background-color: $light-gray;
      transition: $global-transition;
    }
  }

  &:nth-child(even) {
    .accordion__title {
      background-color: $white;
      transition: $global-transition;
    }
  }

  i {
    font-size: 30px;
    margin-right: 16px;
  }

  .accordion__title {
    background: transparent;
    box-sizing: border-box;
    min-height: 87px;
    display: flex;
    align-items: center;

    &:hover {
      background-color: $dark-gray;
      color: $white;

      &::after {
        border-color: $white !important;
      }
    }

    &::after {
      content: '';
      position: absolute;
      border-right: 4px solid $dark-gray;
      border-bottom: 4px solid $dark-gray;
      right: 30px;
      width: 13px;
      height: 13px;
      border-radius: 3px;
      transform: rotate(45deg);

      &:hover {
        border-color: $white;
      }
    }

    &:focus {
      outline: none;
    }

    &:nth-of-type(even) {
      background-color: white;
    }
  }
}

.accordion__item + .accordion__item {
  border: 0 none !important;
}

.accordion__body {
  background-color: $white;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  padding: 0 !important;

  ul {
    li {
      list-style: none;
    }
  }
}
