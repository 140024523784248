.ReactModal__Body--open {
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.ReactModal__Content {
  &-small {
    max-width: max-content !important;
    height: max-content !important;

    .card__header_extra {
      margin-left: 20px;
    }

    .modal__content {
      padding: 30px;
    }

    .btnModalWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
  
      a {
        margin-right: 30px;
        color: rgba(23, 23, 23, 1);
        cursor: pointer;
      }
  
      .btn {
        width: max-content;
      }
    }

    .centeredContent {
      display: block;
      text-align: center;
      width: 100%;
    }
  }

  .modal__header {
    display: flex;
    height: 50px;
    min-height: 94px;
  }

  .modal__content {
    padding-top: 30px;
    padding-bottom: 30px;
    overflow: scroll;
    height: calc(100% - 94px);
    box-sizing: border-box;

    .centeredContent {
      display: flex; 
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }

 
}

.small2 {
  margin: 20% auto;
}
