.formimagelink {
  color: $dark-gray;
  text-decoration: none;
}

.formimage {
  font-size: 20px;
  font-weight: bold;
  width: 331px;
  height: 331px;
  min-width: 331px;
  min-height: 331px;
  background: $white;
  border: 1px solid $light-gray;
  border-radius: 50%;
  position: relative;

  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
