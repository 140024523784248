.dokumente-list {
  width: 100%;
  height: auto;
  background-color: white;
  position: relative;

  &.dokumente-list-striped {
    .dokument-item:nth-child(odd) {
      background-color: $light-gray;
    }
  }

  a {
    text-decoration: none;
  }
}

.dokumente-wrapper {
  .dokument-item {
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    box-sizing: border-box;

    .dokument-item-left {
      align-items: center;
      display: flex;

      i {
        color: $medium-gray;
        font-size: 30px;
        margin-right: 30px;
      }

      span {
        color: $dark-gray;
        font: {
          size: 18px;
          weight: 500;
        }
      }
    }

    .dokument-item-right {
      align-items: center;
      display: flex;

      i {
        color: $primary-color;
        font-size: 30px;
        margin-left: 30px;
      }

      span {
        font: {
          size: 18px;
          weight: 500;
        }

        line-height: 21px;
        color: $dark-gray-trans-20;
      }
    }
  }
}

.site-wrapper {
  .dokument-item {
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    box-sizing: border-box;
    background-color: white;

    @media screen and (max-width: $breakpoint-medium-small) {
      flex-direction: column;
      justify-content: flex-start !important;
      display: flex;
      width: 100%;
    }

    .dokument-item-left {
      align-items: center;
      display: flex;

      i {
        color: $dark-gray;
        font-size: 30px;
        margin-right: 16px;
      }

      span {
        color: $dark-gray;
        font: {
          size: 18px;
          weight: 500;
        }
      }

      @media screen and (max-width: $breakpoint-medium-small) {
        width: 100%;
        display: flex;
        justify-content: flex-start;
      }
    }

    .dokument-item-right {
      align-items: center;
      display: flex;

      i {
        color: $primary-color;
        font-size: 30px;
        margin-left: 30px;
      }

      span {
        font: {
          size: 18px;
          weight: 500;
        }

        line-height: 21px;
        color: $dark-gray-trans-20;
      }

      @media screen and (max-width: $breakpoint-medium-small) {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        padding-left: 110px;
      }
    }
  }
}
