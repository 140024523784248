.check-list,
.check-list-gray {
  background-color: white;
  position: relative;
  box-shadow: 0 0 37px -7px rgba(0, 0, 0, 0.5);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 77px;
    height: 6px;
    width: 60px;
    background-color: $primary-color;
  }

  // Header
  .check-list-headline {
    padding: 30px;
    display: flex;
    justify-content: space-between;

    &::before,
    &::after {
      display: none;
    }

    span {
      font-size: 28px;
      font-weight: bold;
      line-height: 33px;
    }

    i {
      color: $medium-gray-trans-70;
      font-size: 30px;
      margin-right: 20px;
    }

    .counter {
      line-height: 21px;
      font-size: 18px;
    }
  }

  //Main

  .check-list-main {
    .checkbox-wrapper {
      display: flex;
      padding: 30px;
      align-items: center;
      position: relative;

      &:nth-child(odd) {
        background-color: $light-gray;
      }

      p {
        padding-left: 20px;
        color: $dark-gray !important;

        @media screen and (max-width: $breakpoint-medium-small) {
          font-size: 16px;
        }
      }

      label {
        color: $dark-gray !important;
        display: flex;
        align-items: center;
      }
    }

    input {
      display: none;
    }

    label {
      position: relative !important;
      top: unset;
      left: unset;
    }

    input[type='checkbox']:checked + label::before {
      box-shadow: inset 0 0 0 3px #ffffff;
      background-color: #8cad2d;
    }

    label span {
      height: 26px;
      width: 26px;
      min-height: 26px;
      max-height: 26px;
      min-width: 26px;
      max-width: 26px;
      border: 2px solid $dark-gray;
      border-radius: 4px;
      display: inline-block;
      position: relative;
      pointer-events: all;
    }

    [type='checkbox']:checked + span::before {
      content: '\2714';
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $dark-gray;
      width: 100%;
      height: 100%;
      color: $white;
    }
  }

  .checkbox-text {
    display: flex;
    flex-direction: column;
    margin-left: 20px;

    .prim-text {
      font: {
        size: 18px;
        weight: 500;
      }

      line-height: 21px;
    }

    .sec-text {
      font: {
        size: 14px;
        weight: 500;
      }

      line-height: 18px;
      color: $dark-gray-trans-20;
    }
  }

  // Footer
  .check-list-foo {
    height: 106px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid $ultra-light-gray;

    a {
      text-decoration: none;

      span {
        border: 2px solid $primary-color;
        border-radius: 50px;
        padding: 10px 20px;
        font-size: 20px;
        transition: $global-transition;
        color: $dark-gray;

        &:hover {
          background-color: $primary-color;
          color: $white;
          transition: $global-transition;
        }
      }
    }
  }
}

.check-list-gray {
  &::before {
    display: none;
  }

  .checkbox-wrapper {
    i {
      font-size: 30px;
    }
  }
}

.overlay-box {
  .check-list-gray {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: $white-trans-10;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    span {
      text-decoration: line-through;
      color: $medium-gray-trans-30;
    }
  }
}

.check-list-article-dropdown {
  position: absolute;
  left: 160px;
  height: 200px;
  overflow: scroll;
  min-height: 200px;
  z-index: 20;
  background: #fff;
  top: 50px;
  box-shadow: 1px 1px 5px #ccc;
  padding: 10px;
  ul {
    padding: 0;
    li {
      span {
        cursor: pointer;
        position: relative;
        span.close {
            background: $primary-color;
            position: absolute;
            width: 20px;
            height: 20px;
            right: 10px;
            top: 10px;
        }
      }
    }
  }
}
