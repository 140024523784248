@import 'styles/globals';

.icon {
  color: $dark-gray;
  font-size: 30px;
  transition: $global-transition;

  a &:hover,
  &--clickable:hover {
    color: $primary-color;
    transition: $global-transition;
    cursor: pointer;
  }

  &--hidden {
    visibility: hidden;
  }
}

.icon-list {
  a + a,
  .icon + .icon {
    margin-left: 20px;
  }
}


.fa-plane {
  transform: rotate(45deg);
  font-size: 30px;
}
