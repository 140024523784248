@import 'styles/globals';

.card__content {
  width: 100%;
  overflow-x: scroll;
  position: relative;
}

.claim-form,
.Claims .card {
  .btn-disabled {
    a {
      cursor: wait;
    }
  }
}

.claims__list {
  tbody tr:hover {
    cursor: pointer;

    td {
      background-color: $medium-gray-trans-30;
    }
  }

  .icon:hover {
    color: $dark-gray;
  }
}

.claims-form__cloze {
  padding: 15px 30px;
  display: block;
  border: 1px solid $light-gray;
  border-radius: 3px;
  font-weight: normal;
  background-color: white;
  position: relative;

  label {
    display: block;
    position: initial;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    pointer-events: all;
  }

  input {
    display: inline;
    width: 200px;
    padding: 6px 6px 3px;
    border-radius: 0;
    border: 0 solid #f1f1f1;
    background-color: #fbfbfb;
    border-bottom: 2px solid $medium-gray-trans-90;
    font-size: 14px;
    font-weight: 700;
  }

  input:read-only {
    background-color: $light-gray;
  }

  ul {
    list-style: none;
    margin-bottom: 30px;
    position: relative;
  }

  li {
    position: relative;
    width: auto;

    input {
      position: absolute;
      left: 160px;
    }
  }

  li + li {
    padding-top: 22px;
  }
}



.claimChecklistWrapper {
  display: flex;

  > ul, div {
    width: 50%;
  }

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .35s;

    @media screen and (max-width: $breakpoint-medium) {
      justify-content: flex-end;
    }

    @media screen and (max-width: $breakpoint-medium-small) {
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
    }

    .add-button, .remove-button {
      border: 0 none;
      outline: none;
      background: transparent;
      font-size: 24px;
      transition: all .35s;
      padding: 0;

      &:hover{
        cursor: pointer;
      }
    }

    .add-button + .remove-button {
      margin-left: 20px;

      @media screen and (max-width: $breakpoint-medium-small) {
        margin-left: 0;
        margin-top: 20px;
      }
    }

    .remove-button {
      &:hover {
        color: red;
        transition: all .35s;
      }
    }
  }
}

.addButtonWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    display: block;
    margin-right: 10px;
  }

  .add-button {
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    background: white;
    border: 2px solid black;
    border-radius: 5px;
    padding: 5px;
    transition: all .35s;

    &:hover {
      cursor: pointer;
      background: rgba(150,150,150, .2)
    }

    i {
      display: flex;
      align-items: center;
      font-size: 20px;
    }
  }
}

.claimSearchIcon {
  font-size: 16px;
  position: absolute;
  left: 390px;
  top: 4px;

  &:hover {
    cursor: help;
  }
}
