@import '~react-datepicker/dist/react-datepicker.css';
@import 'styles/globals';

.react-datepicker-popper {
  z-index: 9999;
}

.absence-date {
  max-width: 250px;

  @media screen and (max-width: 900px){
    max-width: 175px;
  }

  input {
    padding: 0;
    width: calc(100% - 10px);
    height: 42px;
    border: 2px solid $dark-gray;
    border-radius: 0;
    padding-left: 10px;

  }
}

.react-datepicker-wrapper {
  width: 100%;
  max-height: 82px;
}

.react-datepicker__day {
  &:hover {
    border-radius: 50%;
  }
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected {
  background-color: $primary-color;
  border-radius: 50%;
  transition: $global-transition;
  &:hover {
    background-color: $secondary-color;
    border-radius: 50%;
  }
}
