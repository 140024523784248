.formbutton {
  background: $dark-gray;
  color: $white;
  font-size: 28px;
  font-weight: bold;
  padding: 30px 30px 30px 75px;
  position: relative;
  border-radius: 3px;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: $medium-gray;

    ::before {
      color: $white;
    }
  }

  p {
    margin: 0;
  }

  ::before {
    position: absolute;
    content: '\f029';
    font-family: FontAwesome; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
    font-size: 35px;
    top: 27px;
    left: 30px;
    color: $medium-gray;
    transition: all 0.2s ease-in-out;
  }

  ::after {
    position: absolute;
    content: '\f105';
    font-family: FontAwesome; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
    color: $primary-color;
    font-size: 35px;
    top: 27px;
    right: 30px;
  }
}

.buttonlink {
  color: $white;
  text-decoration: none;
}
