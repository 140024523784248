// Settings
$content-wrapper-inner-hor: 30px;
$content-wrapper-inner-ver: 70px;
$content-wrapper-inner-ver-medium: 40px;
$content-wrapper-inner-ver-small: 30px;
$content-wrapper-outer-hor: 30px;
$content-wrapper-outer-ver: 30px;
$content-wrapper-box-inner-hor: 30px;
$content-wrapper-box-inner-ver: 30px;
$content-wrapper-box-outer-hor: 30px;
$content-wrapper-box-outer-ver: 30px;

.content-wrapper {
  width: 100%;
  display: flex;
  background-color: $light-gray;

  &.banner {
    padding: 30px 0 0;
  }

  &.banner-medium {
    padding: 40px 0 0;
  }

  &.banner-big {
    padding: 70px 0 0;
  }

  &.bg- {
    &white {
      background-color: $white;
    }

    &light-gray {
      background-color: $ultra-light-gray;
    }
  }

  &.inner- {
    &hor {
      padding-left: $content-wrapper-inner-hor;
      padding-right: $content-wrapper-inner-hor;

      @media screen and (max-width: $breakpoint-small) {
        padding-left: 0;
        padding-right: 0;
      }
    }

    &ver {
      padding-top: $content-wrapper-inner-ver;
      padding-bottom: calc(80px - 30px);

      @media screen and (max-width: $breakpoint-medium) {
        padding-top: $content-wrapper-inner-ver-medium;
        padding-bottom: calc(40px - 30px);
      }

      @media screen and (max-width: $breakpoint-small) {
        padding-top: $content-wrapper-inner-ver-small;
        padding-bottom: calc(30px - 30px);
      }
    }
  }

  &.outer- {
    &hor {
      margin-left: $content-wrapper-outer-hor;
      margin-right: $content-wrapper-outer-hor;
    }

    &ver {
      margin-top: $content-wrapper-outer-ver;
      margin-bottom: $content-wrapper-outer-ver;
    }
  }

  &.border-bottom {
    border-bottom: 1px solid $primary-color;
  }

  &.hero-start {
    width: 100%;
    height: 100vh;

    .bg-image-wrapper {
      .bg-image-options {
        &.bg-image-overlay-gfx {
          background-size: 35%;

          @media screen and (max-width: $breakpoint-medium-small) {
            background-size: 45%;
          }

          @media screen and (max-width: $breakpoint-small) {
            background-size: 50%;
          }
        }

        .bg-image-content-wrapper {
          font: {
            size: 100px;
          }

          @media screen and (max-width: $breakpoint-medium-small) {
            font-size: 58px;
          }

          @media screen and (max-width: $breakpoint-small) {
            font-size: 42px;
          }
        }
      }
    }
  }

  &.fullwidth-grid-single-row {
    position: relative;
    background-color: $white;

    &::before,
    &::after {
      content: '';
      display: block;
      width: 50%;
      height: 100%;
      position: absolute;
      z-index: 800;
      top: 0;
      bottom: 0;

      @media screen and (max-width: $breakpoint-medium) {
        display: none;
      }
    }

    &::before {
      background: $primary-color;
      left: 0;
    }

    &::after {
      left: 50%;
    }

    @media screen and (max-width: $breakpoint-medium) {
      padding: 0;
    }

    .grid-wrapper {
      @media screen and (max-width: $breakpoint-medium) {
        padding: 0;
      }

      & > div {
        position: relative;
        z-index: 900;

        @media screen and (max-width: $breakpoint-medium) {
          margin: 0;
        }

        p {
          margin-bottom: 20px;
        }

        &:first-child {
          color: $white;
          padding-right: 50px;

          @media screen and (max-width: $breakpoint-medium) {
            background: $primary-color;
            padding: 30px;
          }

          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            background: $white;
            color: $primary-color;
          }

          p,
          a {
            color: $white;
          }

          .btn-inline {
            &-sub {
              color: $white;
            }

            &::before {
              border-color: transparent $white transparent transparent;
            }

            &:hover {
              color: $white-trans-50;
            }
          }
        }

        &:last-child {
          padding-left: 50px;

          @media screen and (max-width: $breakpoint-medium) {
            padding: 30px;
          }
        }
      }
    }
  }

  &.fullwidth-grid-map-row {
    position: relative;
    background-color: $white;

    @media screen and (max-width: $breakpoint-medium) {
      padding: 0;
    }

    .grid-wrapper {
      padding: 0;

      & > div {
        padding: 0;
        position: relative;
        z-index: 900;
        margin-bottom: 0;

        &:first-child {
          padding: 100px 30px 100px calc(50% - 37.5rem);

          @media screen and (max-width: $global-width) {
            padding: 100px 30px;
          }

          @media screen and (max-width: $breakpoint-medium) {
            padding: 40px 30px;
          }

          @media screen and (max-width: $breakpoint-small) {
            padding: 30px;
          }
        }

        @media screen and (max-width: $breakpoint-medium) {
          margin: 0;
        }

        p {
          margin-bottom: 20px;
        }
      }
    }
  }

  &.fullwidth-grid {
    border-top: 1px solid $primary-color;
    border-bottom: 1px solid $primary-color;

    .grid-wrapper {
      padding: 0;
      border-bottom: 1px solid $primary-color;

      &:last-of-type {
        border: 0 none;
      }

      & > div {
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }

      .text-wrapper {
        height: auto;
        width: 100%;
        padding: 100px;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          margin: 0 0 10px;
          font-size: 24px;
          line-height: inherit;
        }

        .btn {
          margin-top: 10px;
        }

        @media screen and (max-width: $breakpoint-medium) {
          padding: 80px;
        }

        @media screen and (max-width: $breakpoint-small) {
          padding: 30px;
        }
      }
    }
  }

  .content-wrapper-box {
    width: $global-width;
    margin: 0 auto;

    @media screen and (max-width: $global-width) {
      width: 100%;
    }

    &.fullwidth {
      width: 100%;
      margin: 0;
    }

    &.inner- {
      &hor {
        padding-left: $content-wrapper-box-inner-hor;
        padding-right: $content-wrapper-box-inner-hor;
      }

      &ver {
        padding-top: $content-wrapper-box-inner-ver;
        padding-bottom: calc(30px - 30px);
      }
    }

    @media screen and (max-width: $breakpoint-small) {
      &.mobile-inner- {
        &hor {
          padding-left: $content-wrapper-box-inner-hor;
          padding-right: $content-wrapper-box-inner-hor;
        }
      }
    }

    &.outer- {
      &hor {
        margin-left: $content-wrapper-box-outer-hor;
        margin-right: $content-wrapper-box-outer-hor;
      }

      &ver {
        margin-top: $content-wrapper-box-outer-ver;
        margin-bottom: $content-wrapper-box-outer-ver;
      }
    }
  }
}
