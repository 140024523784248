// Chart

.VacationWidget {
  box-shadow: 0 0 37px -7px rgba(0, 0, 0, 0.5);
  width: 100%;
  height: auto;
  background-color: white;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 77px;
    height: 6px;
    width: 60px;
    background-color: $primary-color;
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      list-style: none;
      height: auto;
      padding: 30px;
      box-sizing: border-box;
      font-size: 22px;
      line-height: 25px;
      font-weight: 500;
      display: flex;
      justify-content: space-between;

      &:nth-child(odd) {
        background-color: $light-gray;
      }

      span {
        padding: 2px 10px;
        border-radius: 13px;
        color: white;
        background-color: $dark-gray;
      }
    }
  }

  span {
    i {
      color: $medium-gray-trans-70;
      font-size: 30px;
      margin-right: 20px;
    }
  }

  .headline {
    height: auto;
    display: block;
    padding: 30px;
    box-sizing: border-box;
    font-size: 28px;
    font-weight: bold;
    line-height: 33px;
    border-bottom: 2px solid $light-gray;
  }

  .VacationWidget__item {
    span {
      transition: $global-transition;
    }

    &:hover {
      cursor: pointer;

      span {
        transition: $global-transition;
        background-color: $primary-color;
      }
    }
  }
}

.VacationWidget-inline {
  display: flex;
  width: 100%;

  > div {
    &:first-of-type {
      width: 60%;
    }

    &:last-of-type {
      width: 400px;
    }
  }

  @media screen and (max-width: $breakpoint-medium-small) {
    flex-direction: column;

    > div {
      &:first-of-type {
        width: 100%;
      }

      &:last-of-type {
        width: 100%;
      }
    }
  }
}

.VacationWidget__chart {
  @media screen and (max-width: $breakpoint-medium-small){
    border-top: 2px solid $dark-gray;
    margin-bottom: 80px;
  }
}

.ProfilOverview {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  @media screen and (max-width: $breakpoint-small) {
    padding: 0 30px 40px;
  }

  .profil-col {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (max-width: $breakpoint-medium) {
      width: 50%;

      &:last-child {
        width: 100%;
      }
    }

    @media screen and (max-width: $breakpoint-medium-small) {
      width: 100%;
    }

    &:nth-child(2) {
      border-left: 2px solid $secondary-color;
      border-right: 2px solid $secondary-color;

      @media screen and (max-width: $breakpoint-medium) {
        border-bottom: 2px solid $secondary-color;
        border-right: 0 none;
        padding-top: 40px;
        padding-bottom: 40px;
      }

      @media screen and (max-width: $breakpoint-medium-small) {
        border-left: 0 none;
      }
    }

    &:nth-child(3) {
      padding-left: 60px;

      @media screen and (max-width: $breakpoint-medium) {
        padding-left: 0;
        padding-top: 40px;
      }
    }

    .headline-box {
      * {
        font-size: 30px;
        line-height: 30px;
        text-align: center;
      }
    }

    .settings {
      ul {
        margin: 0;
        padding: 0;

        li {
          list-style: none;

          a {
            display: block;
            width: 100%;
            padding: 20px 0 10px;
            text-decoration: none;
            font: {
              size: 18px;
              weight: 600;
            }

            color: $secondary-color;
            text-align: center;
            border-bottom: 2px solid $primary-color;
          }
        }
      }
    }

    .profil-status {
      position: relative;

      .profil-status-chart {
        position: absolute;

        // Irgendwas stimmt hier bei nicht.... FIXME: TODO:
        min-width: 258px !important;
        min-height: 258px !important;
      }

      .profil-status-text-wrapper {
        width: 120px;
        height: 70px;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: calc(50% - 35px);
        left: calc(50% - 60px);

        .value {
          font: {
            size: 62px;
            weight: bold;
          }

          line-height: 73px;
          text-align: center;
          color: $primary-color;

          @media screen and (max-width: $breakpoint-small) {
            border-left: 0 none;
            font: {
              size: 32px;
            }
          }
        }

        .text {
          font: {
            size: 16px;
            weight: 500;
          }

          line-height: 16px;
          text-align: center;

          @media screen and (max-width: $breakpoint-small) {
            border-left: 0 none;
            font: {
              size: 14px;
            }
          }
        }
      }
    }
  }
}

.valuesForChart {
  width: 516px !important;
  height: 258px !important;
  max-width: 516px !important;
  max-height: 258px !important;
  margin-left: calc(50% - 260px);
}

.profil-status {
  margin-top: 40px;
}
