@import 'globals';

a > i.fa {
  cursor: pointer;
}

.red-mark::before {
  content: '';
  position: absolute;
  left: 0;
  height: 100%;
  width: 6px;
  background-color: red;
}

input.absence-input {
  font-size: 16px;
  padding: 5px;
  width: 100%;
  min-width: 200px;
  min-height: 32px;
  border: 1px solid $dark-gray;
  @media screen and (max-width: $breakpoint-medium-small) {
    min-width: 125px;
  }
}
