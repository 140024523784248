#login-page {
  background: white !important;
  border: 0 none;

  &::after {
    display: none;
  }

  #main-side {
    padding: 0;
  }
}

.login-site {
  width: 100vw;
  height: 100vh;
  background: white;

  .page-wrapper {
    &::after {
      display: none;
    }
  }

  #main-side {
    padding: 0 !important;
    background-color: white;
  }
}

.login {
  width: 500px;
  height: auto;
  margin: 0 auto;

  @media screen and (max-width: $breakpoint-small) {
    width: 100%;
  }

  .login-header {
    transition: $global-transition;
    height: 100px;
    display: flex;
    justify-content: center;
    margin: 40px 0;

    img {
      height: 100%;
      width: auto;
    }
  }

  .login-body {
    background-color: $ultra-light-gray;
    padding: 40px;
    box-sizing: border-box;
    position: relative;
    transition: $global-transition;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 40px;
      height: 6px;
      width: 60px;
      background-color: $primary-color;
      transition: $global-transition;
    }

    .login-input {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      transition: $global-transition;

      label {
        font-size: 24px !important;
        font-weight: 700 !important;
        line-height: 26px !important;
        margin-bottom: 10px !important;
        transition: $global-transition !important;
        position: relative !important;
        display: block;
        padding: 0;
        left: 0;
        top: 0;
      }

      input[type='text'],
      input[type='password'] {
        width: 100% !important;
        height: 40px !important;
        box-sizing: border-box !important;
        transition: $global-transition !important;
        font-size: 18px;
        color: $dark-gray;
        border: 1px solid $dark-gray-trans-20;
        font-weight: normal;
        padding: 0 0 0 20px;
        border-radius: 0;
      }
    }

    .login-submit {
      input[type='submit'], button {
        width: 100%;
        height: 50px;
        background: $primary-color;
        color: white;
        font-size: 20px;
        transition: $global-transition;
        cursor: pointer;
        padding: 0;
      }
    }
  }
}
