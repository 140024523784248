.absenceButton {
  button {
    width: 100%;
    height: 90px;
    border-radius: 3px;
    border: 1px solid $medium-gray;
    color: white;
    font-size: 22px;
    font-weight: bold;
    cursor: pointer;
    &.present {
      background-color: $green;
    }
    &.vacation {
      background-color: $blue;
    }
    &.absence{
      background-color: red;
    }
    &.school {
      background-color: $orange;
    }
    &.free {
      background-color: $light-blue;
    }
    &.absenceReason{
      background-color: $yellow;
      input {
        background-color: $yellow;
        border: 0 none;
        padding: 0;
        text-align: center;
        width: 100%;
      }
    }
  }
}
