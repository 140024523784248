#header-main {
  display: block;
  position: sticky;
  width: 100px;
  background-color: transparent;
  box-sizing: border-box;
  top: 0;
  height: 100vh;
  z-index: 9999;
  transition: $global-transition;

  &.active {
    display: block;
  }

  .header-wrapper {
    transition: $global-transition;
    position: relative;
    background: $dark-gray;
    height: 100%;
    overflow: scroll;
    width: 100px;

    .header-open {
    display: block;

      &.active {
        left: 380px;

        &::after {
          transform: rotate(-45deg);
          left: 9px;
        }
      }

      position: fixed;
      left: 100px;
      top: 125px;
      width: 30px;
      height: 60px;
      background-color: $dark-gray;
      transition: $global-transition;
      cursor: pointer;

      .active {
        transform: rotate(-45deg);
      }

      &::after {
        content: '';
        position: absolute;
        border-left: 3px solid white;
        border-top: 3px solid white;
        border-right: 1px solid $dark-gray;
        border-bottom: 1px solid $dark-gray;
        width: 10px;
        height: 10px;
        transform: rotate(135deg);
        top: 24px;
        left: 5px;
        border-radius: 3px;
      }
    }

    .header-top {
      display: flex;
      align-items: center;
      border-bottom: 1px solid $medium-gray;
      padding: 18px 30px;
      box-sizing: border-box;
      flex-direction: column;
      justify-content: normal;

      .initials {
        margin-top: 20px;
      }


      .header-logo-wrapper {
        width: auto;
        height: 48px;

        img {
          width: auto;
          height: 48px;
        }
      }

      .initials {
        width: 48px;
        max-width: 48px;
        height: 48px;
        max-height: 48px;
        border-radius: 50%;
        background-color: $medium-gray;

        a {
          text-decoration: none;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        span {
          color: $white;
          font-size: 20px;
          line-height: 24px;
        }
      }
    }

    ul {
      padding: 0;
      margin: 0;

      li {
        list-style: none;
        border-top: 1px solid $medium-gray;
        width: 380px;
        &:last-of-type {
          border-bottom: 1px solid $white-trans-10;
        }

        a {
          text-decoration: none;
          color: white;
          font-size: 20px;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          padding: 10px 30px;
          box-sizing: border-box;
          transition: $global-transition;

          &:hover {
            color: $primary-color;
            transition: $global-transition;

            i {
              color: $primary-color;
            }
          }

          i {
            color: white;
            font-size: 24px;
            width: auto;
          }

          span {
            margin-left: 30px;
          }
        }

        ul {
          li {
            a {
              i {
                color: rgba(254, 254, 254, 0.2);
              }

              span {
                color: rgba(254, 254, 254, 0.2);
              }

              &:hover {
                i {
                  color: $white;
                }

                span {
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
  }

  &.open {
  width: 410px;

    .header-wrapper {
      width: 380px;
      .header-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .initials {
          margin-top: 0;
        }
      }

      #main-nav {

        #navigation {
          ul {
            li {
              a {
                justify-content: flex-start;

                span {
                  display: block;
                }

                .counter {
                  display: flex;
                }

                i {
                  margin: 0;
                  width: 20px;
                }
              }

              // Subnav -- Open
              ul {
                li {
                  a {
                    &:hover {
                      i {
                        color: white;

                      }

                      span {
                        color: white;
                      }
                    }

                    span {
                      color: rgba(254, 254, 254, 0.2);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .has-subnav {
      a {
        &::after {
          left: 335px !important;
          transition: $global-transition;
        }
      }
    }
  }

  #main-nav {
    .main-nav-wrapper {
      transition: $global-transition;
      position: relative;
      width: 100%;
      height: 100%;
    }

    .counter {
      position: absolute;
      right: 30px;
      top: 14px;
    }

    #navigation {
      ul {
        li {
          width: auto;
          position: relative;

          &.mainNav__itemActive {
            a {
              i {
                color: $primary-color !important;
              }

              span {
                color: $primary-color !important;
              }
            }
          }

          span {
            display: none;
          }
          .counter {
            display: none;
          }


          a {
            display: flex;
            justify-content: center;

            i {
              margin: 0;
            }
          }

          // Ausblenden vom Subnav
          ul {
            display: none;
          }

          &.has-subnav {
            a {
              &::after {
                content: '';
                display: block;
                width: 10px;
                height: 10px;
                border-top: 3px solid $white;
                border-right: 3px solid $white;
                border-bottom: 1px solid $dark-gray;
                border-left: 1px solid $dark-gray;
                border-radius: 3px;
                transform: rotate(135deg);
                position: absolute;
                right: 30px;
                top: 13px;
                left: 75px;
                transition: $global-transition;
              }
            }
          }

          &.open-submenu {
            &:hover {
              color: $primary-color;
            }

            a {
              &::after {
                top: 19px;
                transform: rotate(-45deg);
              }
            }

            ul {
              // nötig um 'ul' zu öffnen
              display: block;

              a {
                &::after {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
