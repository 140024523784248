.button {
  width: 100%;
  border-radius: 5px;
  background-color: $dark-gray;
  transition: $global-transition;

  &:hover {
    a {
      color: $primary-color;
      transition: $global-transition;

      i {
        color: $primary-color;
        transition: $global-transition;
      }
    }
  }

  a {
    color: white;
    text-decoration: none;
    font-size: 28px;
    line-height: 33px;
    transition: $global-transition;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 30px;
    box-sizing: border-box;

    @media screen and (max-width: 1540px) {
      font-size: 20px;
    }

    @media screen and (max-width: 1100px) {
      font-size: 17px;
    }

    @media screen and (max-width: 1350px) {
      padding: 30px 15px;
    }

    @media screen and (max-width: 1023px) {
      font-size: 15px;
    }

    i {
      font-size: 30px;
      margin-right: 20px;
      color: $medium-gray-trans-70;
      transition: $global-transition;

      @media screen and (max-width: 1650px) {
        display: none;
      }
    }
  }

  .arrow-right {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 12px;
      height: 12px;
      border-top: 5px solid $primary-color;
      border-right: 5px solid $primary-color;
      transform: rotate(45deg);
      top: 38px;
      right: 30px;
      border-radius: 3px;

      @media screen and (max-width: 1540px) {
        width: 10px;
        height: 10px;
      }

      @media screen and (max-width: 1100px) {
        width: 9px;
        height: 9px;
      }

      @media screen and (max-width: 1350px) {
        right: 15px;
      }

      @media screen and (max-width: 1023px) {
        width: 10px;
        height: 10px;
        border-top: 3.5px solid $primary-color;
        border-right: 3.5px solid $primary-color;
      }
    }
  }
}

// Btn2

.btn-2 {
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    text-decoration: none;

    span {
      border: 2px solid $primary-color;
      border-radius: 50px;
      padding: 10px 20px;
      font-size: 20px;
      transition: $global-transition;
      color: $dark-gray;
      display: block;

      &:hover {
        background-color: $primary-color;
        color: $white;
        transition: $global-transition;
      }

      i {
        margin-right: 15px;
      }
    }
  }
}

.btn-3 {
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    text-decoration: none;

    span {
      border: 2px solid $primary-color;
      border-radius: 50px;
      padding: 10px 20px;
      font-size: 18px;
      font-weight: 300;
      transition: $global-transition;
      color: $white !important;
      background-color: $primary-color;

      &:hover {
        background-color: $white;
        color: $primary-color !important;
        transition: $global-transition;
      }

      i {
        margin-right: 15px;
      }
    }
  }
}

.btn-form-wrapper {
  height: 82px;
  display: flex;
  justify-content: center;

  > a {
    background-color: $primary-color;

    span {
      color: white;
    }

    &:hover {
      background: white;
      color: $primary-color;
      border: 1px solid $primary-color;
    }
  }

  .btn-3 {
    a {
      color: white;

      span {
        color: white;

        &:hover {
          color: $primary-color;
        }
      }
    }
  }
}

.btn-disabled {
  a {
    cursor: not-allowed;
    &::after {
      background-color: $light-gray;
    }

    span {
      border: 2px solid $dark-gray-trans-20;
      color: $dark-gray-trans-20 !important;
      background-color: $light-gray;

      &:hover {
        color: $medium-gray;
        background-color: $light-gray;
      }
    }
  }
}
