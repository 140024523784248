.table-col-wide {
  min-width: 250px;
}

.sticky {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  position: absolute;
  top: 0;
  z-index: 1;
  transition: top 0.3s ease 0.1s;
}

.week-btn {
  cursor: pointer;
}

span.info {
  box-sizing: border-box;
  border-bottom: 1px solid #c1c1c1;
  border-top: 1px solid #c1c1c1;
}

.table-re .table-content-1 span, .table .table-content-1 span {
  min-height: 85px !important;
}


.table .table-content-1 span {
  min-height: 85px !important;
}

.table.no-shadow {
  .table-content-1 {
    > div { 
      &:first-of-type{
        span.info {
          border-top: 0 none;   
        }
      }
    }

    span {
      background-color: #fff;
      font-size: 16px;
      line-height: 19px;
      min-height: 19px!important;
      display: flex;
      align-items: center;
      height: 65px;
      pointer-events: all;
      padding: 0 20px;
      max-width: 100%;
      width: auto;
      border-right: 1px solid #c1c1c1;

      &.info {
        border-right: 1px dashed #c1c1c1;
        border-top: 1px dashed #c1c1c1;
        background: $light-gray;
        box-sizing: border-box;
      }
    }
  }
}