@import 'styles/globals';

.checkbox {
  box-sizing: border-box;

  &::before {
    // Fixme: Hides .check-list::before, remove when this class is removed from component
    display: none;
  }

  &--readonly {
    background-color: $light-gray;
    color: $dark-gray-trans-70;

    .boxChecked {
      border-color: $dark-gray-trans-70 !important;

      &::before {
        background-color: $dark-gray-trans-70;
      }
    }
  }

  &__placeholder {
    color: $dark-gray-trans-70;
  }

  & .checkbox-wrapper {
    background-color: inherit !important;
    padding-top: 36px !important;
    padding-bottom: 0 !important;

    .checkbox-text {
      font-size: 22px;
      font-weight: 700;
    }
  }

  border: 1px solid $light-gray;
  height: 82px;
}
