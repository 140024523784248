@import '../assets/styles/globals';

.CategoryForm .dokumente-list {
  border: 1px solid rgb(237, 237, 237);
}

.CategoryForm .dokumente-list .dokument-item + .dokument-item {
  border-top: 1px solid rgb(237, 237, 237);
}

.CategoryForm .dokumente-list .dokument-item + .dokument-item:last-of-type {
  border-top-width: 3px;
}

.CategoryForm .CategoryForm__input-placeholder {
  height: 82px; // Height of a default input item
}

.contact-persons {
  margin: 0;
  padding: 0;
  color: $dark-gray;

  li {
    border-bottom: 1px solid $light-gray;
    padding: 30px;
    box-sizing: border-box;

    &:last-of-type {
      border-bottom: 0 none;
    }

    p {
      font-size: 20px;
      margin: 10px 0;
      color: $dark-gray;

      &:first-of-type {
        margin-top: 0;
        margin-bottom: 0;
        font-weight: bold;
        color: $dark-gray;
      }
    }

    .contact-name {
      font-size: 18px;
      display: flex;
      align-items: center;
    }

    i {
      font-size: 25px;
      color: $dark-gray-trans-50;
      width: 25px;
    }

    .contact-phone {
      font-size: 18px;
      display: flex;
      align-items: center;
    }

    .contact-mail {
      font-size: 18px;
      transition: $global-transition;
      margin: 10px 0;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: $dark-gray;

      &:hover {
        color: $primary-color;

        i {
          color: $primary-color !important;
        }
      }
    }
  }
}

.general-information {
  display: flex;
  flex-direction: column;
  padding: 20px 0;

  span {
    &:first-of-type {
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      color: $light-gray;
    }

    &:last-of-type {
      font-size: 18px;
      font-weight: 500;
      line-height: 21px;
      color: $dark-gray;
    }
  }
}

> .contact-persons {
  padding: 20px 30px;
  box-sizing: border-box;
  border-bottom: 2px solid $ultra-light-gray;

  &:last-of-type {
    border: 0 none;
  }

  .contact-content {
    span {
      a {
        padding: 0;
        border: 0 none;
        height: auto;
      }
    }
  }
}

> .general-information {
  padding: 20px 30px;
  box-sizing: border-box;
  width: 100%;
}

.single-item {
  margin: 0 !important;
  padding: 30px;
}

.react-contextmenu {
    background: white;
    padding: 5px;
    border-radius: 4px;
    box-shadow: 3px 3px 5px #ccc;
}

.react-contextmenu-item {
  margin: 10px 0px;
  cursor: pointer;
}
.react-contextmenu-item.react-contextmenu-item--divider {
  border-bottom: 1px solid #ddd;
}

#newSubfolder {
  display: block;
  position: absolute;
  background: #c1c1c1;
  width: 50%;
  padding: 50px;
}

#newSubfolder .fa-times {
  display: block;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
.btn-grp {
  margin-top: 25px;
}

.firstLevelF{
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.secondLevelF{
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.thirdLevelF{
  padding-left: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.forthLevelF{
  padding-left: 40px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.fifthLevelF{
  padding-left: 50px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.cat-tile {
  .card__header {
    min-height: 106px;
    padding: 20px;
    .card__header_icon {
      font-size: 24px;
      margin-right: 12px;
      line-height: 30px;
    }
    .card__header_title {
      font-size: 22px;
    }
    .card__header_extra {
      min-width: 50px;
      line-height: 30px;
      .icon-list {
        .icon {
          font-size: 22px;
        }
        .fa-pencil{
          margin-left: 10px;
        }
      }
    }
  }
}
