@import '~react-tabs/style/react-tabs.css';

.react-tabs__tab {
  background: #c1c1c1;
  color: #fefefe !important;
  padding: 20px !important;
  font-size: 20px;
  font-weight: 700;
  justify-content: center;
  display: flex !important;
  align-items: center;
  width: 100%;

  & + & {
    margin-left: 2px;
  }

  &--disabled {
    cursor: not-allowed !important;
  }

  &--selected {
    background: #303030 !important;
    color: #fefefe !important;
  }
}

.react-tabs__tab-list {
  border: 0 none !important;
  padding: 0 70px !important;
  list-style: none;
  margin: 0 0 20px;
  display: flex;
  hyphens: auto;
}

.react-tabs__tab-panel {
  .content-wrapper {
    background-color: transparent !important;
    padding: 0 30px;
    box-sizing: border-box;

    .content-wrapper-box {
      .grid-wrapper {
        padding: 0 30px !important;
      }
    }
  }
}
