@import 'globals';
table.sticky-table {
  position: relative;
}
tr.sticky-table-header {
  th {
    position: fixed;
    top: 0;
  }
}

.top-modal {
  z-index: 10000000;
}

