.news-list {
  width: 100%;
  height: auto;
  background-color: white;
  position: relative;
  box-shadow: 0 0 37px -7px rgba(0, 0, 0, 0.5);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 77px;
    height: 6px;
    width: 60px;
    background-color: $primary-color;
  }

  // Header
  .news-list-headline {
    padding: 30px;
    display: flex;
    justify-content: space-between;

    span {
      font-size: 28px;
      font-weight: bold;
      line-height: 33px;
    }

    i {
      color: $medium-gray-trans-70;
      font-size: 30px;
      margin-right: 20px;
    }
  }

  //Main

  .check-list-main {
    > :nth-child(odd) {
      background-color: $light-gray;
    }

    .news-short {
      padding: 30px;
      display: flex;
      flex-direction: column;

      .news-short-title {
        font-size: 21px;
        line-height: 1;
        font-weight: bold;
      }

      > div {
        margin-top: 15px;

        p {
          margin: 0 0 7px;
          font-size: 18px;
          font-weight: 500;
          line-height: 26px;
          background-color: transparent;
        }
      }



      .news-short-main {
        font: {
          size: 18px;
          weight: bold;
        }

        line-height: 26px;
        color: $dark-gray;
        background-color: transparent;

        > p {
          display: inline;
        }
      }

      a {
        font: {
          size: 18px;
          weight: bold;
        }

        width: max-content;
        line-height: 26px;
        color: $primary-color;
        text-decoration: none;
        background-color: transparent;
        margin-top: 10px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  // Footer
  .news-list-foo {
    height: 106px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid $ultra-light-gray;

    a {
      text-decoration: none;

      span {
        border: 2px solid $primary-color;
        border-radius: 50px;
        padding: 10px 20px;
        font-size: 20px;
        transition: $global-transition;
        color: $dark-gray;

        &:hover {
          background-color: $primary-color;
          color: $white;
          transition: $global-transition;
        }
      }
    }
  }
}
