.news-bild {
  position: relative;
  width: 100%;
  display: flex;
  background-color: white;

  @media screen and (max-width: $breakpoint-medium-small) {
    flex-direction: column;
  }

  .img-wrapper {
    width: 400px;
    height: auto;
    min-width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;

    a {
      width: 100%;
      height: 100%;
      display: inherit;
      justify-content: center;
      align-items: center;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    @media screen and (max-width: $breakpoint-medium-small) {
      width: 100%;
      height: auto;
      max-width: 100px
    }

    img {
      @media screen and (max-width: $breakpoint-medium-small) {
        width: 100%;
        height: auto;
      }
    }
  }

  .delete-button {
    position: absolute;
    right: 20px;
    top: 47%;
  }
}

.news-bild-content {
  padding: 30px 90px 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > div {
    margin-top: 15px;

    p {
      margin: 0 0 7px;
      line-height: 23px;
    }
  }

  span {
    color: $medium-gray-trans-30;
  }

  .headline-news {
    font: {
      size: 26px;
      weight: bold;
    }

    line-height: 32px;
    color: $dark-gray;
  }

  p {
    font: {
      size: 16px;
      weight: normal;
    }

    line-height: 20px;
  }
}

.news {
  .react-tabs {
    > ul {
      padding: 0 !important;
      background: white;
      margin: 0;
    }

    //.react-tabs__tab-panel {
    //  .news-bild {
    //    &:nth-child(odd) {
    //      background: #ededed;
    //    }
    //  }
    //}
  }
}

.noNewsFound {
  padding: 30px 90px 30px 30px;
  display: flex;
  flex-direction: column;
  line-height: 20px;
}

.accordion__body {
  .check-list-main {
    > .news-short {
      border-top: 1px solid black;

      &:last-of-type {
        border-bottom: 1px solid black;
      }

      .news-short-title {
        color: black;
      }
      &:nth-child(even) {
        background: $white;
      }
      &:nth-child(odd) {
        background: $white;
      }
    }
  }
}
