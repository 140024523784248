@import 'globals';

/* UserInfoPopup.scss */

$popup-background: #fff;
$popup-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
$popup-width: 320px;
$popup-padding: 5px;
$popup-radius: 8px;
$text-color: #666;
$transition-time: 0.2s;

/* UserInfoPopup.scss */

$user-avatar-size: 60px;

.hoverable-user {
  position: relative;
  cursor: pointer;

  .user-info-popup {
    position: absolute;
    top: 10%;
    left: 0;
    z-index: 10;
    width: $popup-width;
    background-color: $popup-background;
    box-shadow: $popup-shadow;
    padding: $popup-padding;
    border-radius: $popup-radius;
    opacity: 0;
    visibility: hidden;
    transition: opacity $transition-time ease, visibility $transition-time ease, transform $transition-time ease;
    transform: translateY(10px); // Moves the popup slightly down for a smooth appearance

    &::before {
      content: "";
      position: absolute;
      top: -6px;
      left: 16px;
      border-width: 6px;
      border-style: solid;
      border-color: transparent transparent $popup-background transparent;
    }

    .user-avatar {
      width: $user-avatar-size;
      height: $user-avatar-size;
      border-radius: 50%;
      margin-right: 12px;
      float: left;
      object-fit: cover;
    }

    .user-icon {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      margin-right: 12px;
      float: left;
      object-fit: cover;
      color: #ccc;
    }

    .user-details {
      margin-left: 60px;

      h4 {
        margin: 0;
        font-size: 1em;
        font-weight: bold;
        color: $text-color
      }

      p {
        margin: 4px 0;
        font-size: 0.9em;
        color: #666;
      }
    }
  }

  &:hover .user-info-popup {
    opacity: 1;
    visibility: visible;
  }
}

.hoverable-user:hover .user-info-popup {
  opacity: 1;
  visibility: visible;
  transform: translateY(0); // Reset transform when visible
}

table {
  margin-bottom: 40px;
}
