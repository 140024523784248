.large-0 {
  display: none;
}

.large-1 {
  width: calc(100% / 12);
}

.large-2 {
  width: calc((100% / 12) * 2);
}

.large-3 {
  width: calc((100% / 12) * 3);
}

.large-4 {
  width: calc((100% / 12) * 4);
}

.large-5 {
  width: calc((100% / 12) * 5);
}

.large-6 {
  width: calc((100% / 12) * 6);
}

.large-7 {
  width: calc((100% / 12) * 7);
}

.large-8 {
  width: calc((100% / 12) * 8);
}

.large-9 {
  width: calc((100% / 12) * 9);
}

.large-10 {
  width: calc((100% / 12) * 10);
}

.large-11 {
  width: calc((100% / 12) * 11);
}

.large-12 {
  width: 100%;
}

@media screen and (max-width: 1200px) {
  .medium-0 {
    display: none;
  }
  .medium-1 {
    width: calc(100% / 12);
  }

  .medium-2 {
    width: calc((100% / 12) * 2);
  }

  .medium-3 {
    width: calc((100% / 12) * 3);
  }

  .medium-4 {
    width: calc((100% / 12) * 4);
  }

  .medium-5 {
    width: calc((100% / 12) * 5);
  }

  .medium-6 {
    width: calc((100% / 12) * 6);
  }

  .medium-7 {
    width: calc((100% / 12) * 7);
  }

  .medium-8 {
    width: calc((100% / 12) * 8);
  }

  .medium-9 {
    width: calc((100% / 12) * 9);
  }

  .medium-10 {
    width: calc((100% / 12) * 10);
  }

  .medium-11 {
    width: calc((100% / 12) * 11);
  }

  .medium-12 {
    width: 100%;
  }
}

@media screen and (max-width: $breakpoint-medium-small) {
  .medium-small-0 {
    display: none;
  }

  .medium-small-1 {
    width: calc(100% / 12);
  }

  .medium-small-2 {
    width: calc((100% / 12) * 2);
  }

  .medium-small-3 {
    width: calc((100% / 12) * 3);
  }

  .medium-small-4 {
    width: calc((100% / 12) * 4);
  }

  .medium-small-5 {
    width: calc((100% / 12) * 5);
  }

  .medium-small-6 {
    width: calc((100% / 12) * 6);
  }

  .medium-small-7 {
    width: calc((100% / 12) * 7);
  }

  .medium-small-8 {
    width: calc((100% / 12) * 8);
  }

  .medium-small-9 {
    width: calc((100% / 12) * 9);
  }

  .medium-small-10 {
    width: calc((100% / 12) * 10);
  }

  .medium-small-11 {
    width: calc((100% / 12) * 11);
  }

  .medium-small-12 {
    width: 100%;
  }
}

@media screen and (max-width: $breakpoint-small) {
  .small-0 {
    display: none;
  }

  .small-1 {
    width: calc(100% / 12);
  }

  .small-2 {
    width: calc((100% / 12) * 2);
  }

  .small-3 {
    width: calc((100% / 12) * 3);
  }

  .small-4 {
    width: calc((100% / 12) * 4);
  }

  .small-5 {
    width: calc((100% / 12) * 5);
  }

  .small-6 {
    width: calc((100% / 12) * 6);
  }

  .small-7 {
    width: calc((100% / 12) * 7);
  }

  .small-8 {
    width: calc((100% / 12) * 8);
  }

  .small-9 {
    width: calc((100% / 12) * 9);
  }

  .small-10 {
    width: calc((100% / 12) * 10);
  }

  .small-11 {
    width: calc((100% / 12) * 11);
  }

  .small-12 {
    width: 100%;
  }
}

.grid-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;

  @media screen and (max-width: 1250px) {
    padding: 0;
  }

  @media screen and (max-width: $breakpoint-small) {
    display: block;
  }

  @media screen and (max-width: $global-width) {
    width: 100%;
    margin: 0;
  }

  > div {
    margin-bottom: $global-gutter;
    padding: 0 35px;
    box-sizing: border-box;
  }

  &.fullwidth {
    padding: 0;

    > div {
      padding: 0;
    }
  }
}


