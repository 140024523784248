@import 'globals';

.custom-dropdown {
  .dropdownFilter {
      width: 100%;
    ul {
      li {
        a {
          width: 100%;
        }
      }
    }
  }
}

.custom-input {
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  padding: 10px 5px;
  border: 2px solid #303030;
  line-height: 22px;

}

.red-bg {
  background-color: $primary-color-lighter !important;
  color: $white !important;
  padding: 0 5px !important;
  border-radius: 3px !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  max-height: 32px;
}

.white-status-bg {
  background-color: $white !important;
  color: $black !important;
  padding: 0 5px !important;
  border-radius: 3px !important;
  border: 1px solid $dark-gray-trans-70 !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  max-height: 32px;
}

.location-text {
  padding: 10px;
  input {
    max-width: 300px;
  }
}

.checkbox-wrapper-rating {
  display: flex;
  padding: 20px;
  align-items: center;
  position: relative;
}
.rating-button {
  font-size: 40px;
  color: $primary-color;
  margin: 10px;
  cursor: pointer;
}

.locked {
  opacity: 0.2;
}
